import Vue from 'vue';
import Router from 'vue-router';
import AppLayout from '../components/admin/AppLayout';
import firebase from 'firebase';
// import { extendWith } from 'lodash'

Vue.use(Router);

const EmptyParentComponent = {
  template: '<router-view :key="$route.fullPath"></router-view>',
};


const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: {path: '/'},
    },
    {
      path: '/auth',
      component: EmptyParentComponent,
      children: [
        {
          name: 'login',
          path: 'login',
          component: () => import('../components/auth/Login.vue'),
          props: (route) => {
            return {
              error: route.query.error,
            };
          },
          meta: {
            allowGuest: true,
          },
        },
        {
          name: 'logout',
          path: 'logout',
          // component: () => import('../components/auth/login/Login.vue'),
          beforeEnter: (to, from, next) => {
            firebase.auth().signOut().then(() => {
              next({
                path: '/login',
                query: {
                  redirect: to.fullPath,
                },
              });
            });
          },
        },
        {
          path: '',
          redirect: {name: 'login'},
        },
      ],
      meta: {
        allowGuest: true,
      },
    },
    {
      path: '/404',
      component: EmptyParentComponent,
      children: [
        {
          name: 'not-found-advanced',
          path: 'not-found-advanced',
          component: () => import('../components/pages/404-pages/VaPageNotFoundSearch.vue'),
          meta: {
            allowGuest: true,
          },
        },
        {
          name: 'not-found-simple',
          path: 'not-found-simple',
          component: () => import('../components/pages/404-pages/VaPageNotFoundSimple.vue'),
          meta: {
            allowGuest: true,
          },
        },
        {
          name: 'not-found-custom',
          path: 'not-found-custom',
          component: () => import('../components/pages/404-pages/VaPageNotFoundCustom.vue'),
          meta: {
            allowGuest: true,
          },
        },
        {
          name: 'not-found-large-text',
          path: '/pages/not-found-large-text',
          component: () => import('../components/pages/404-pages/VaPageNotFoundLargeText.vue'),
          meta: {
            allowGuest: true,
          },
        },
      ],
    },

    {
      name: 'Admin',
      path: '/',
      component: AppLayout,
      children: [
        {
          name: '',
          path: '',
          component: () => import('../components/dashboard/Dashboard.vue'),
          alias: '',
          default: true,
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          redirect: '/',
        },
        {
          name: 'profile',
          path: 'profile',
          component: () => import('../components/profile/Index.vue'),
        },
        {
          name: 'token',
          path: 'token',
          component: () => import('../components/token/Index.vue'),
        },
        {
          name: 'organizations',
          path: 'organizations',
          component: EmptyParentComponent,
          children: [
            {
              name: 'index',
              path: '/',
              component: () => import('../components/organizations/Index.vue'),
            },
            {
              name: 'order-forms',
              path: 'order-forms/:uid',
              component: () => import('../components/organizations/OrderForms.vue'),
            },
            {
              name: 'organization-details',
              path: 'details/:uid',
              component: () => import('../components/organizations/DetailsView.vue'),
            },
            {
              name: 'org-create-users',
              path: 'users/generate/:uid',
              component: () => import('../components/organizations/CreateMultipleUsers.vue'),
            },
            {
              name: 'org-list-procedure',
              path: 'procedure/list/:uid',
              component: () => import('../components/organizations/ProcedureList.vue'),
            },
            {
              name: 'org-procedure-details',
              path: 'procedure/details/:orguid/:uid',
              component: () => import('../components/organizations/ProcedureDetails.vue'),
            },
            {
              name: 'new',
              path: 'new',
              component: () => import('../components/organizations/Create.vue'),
            },
            {
              name: 'edit',
              path: 'edit/:uid',
              component: () => import('../components/organizations/Edit.vue'),
            }
          ],
        },
        {
          name: 'users',
          path: 'users',
          component: EmptyParentComponent,
          children: [
            {
              name: 'adminuser',
              path: 'adminuser',
              component: () => import('../components/users/admin/Admin.vue'),
            },
            {
              name: 'findusers',
              path: 'findusers',
              component: () => import('../components/users/regular/FindUsers.vue'),
            },
            {
              name: 'createuser',
              path: 'createuser',
              component: () => import('../components/users/regular/CreateUser.vue'),
            },
            {
              name: 'edituser',
              path: 'edituser/:uid',
              component: () => import('../components/users/regular/EditUser.vue'),
            },
            {
              name: 'releaseList',
              path: 'releaseList',
              component: () => import('../components/users/release/ReleaseList.vue'),
            },
            {
              name: 'userProcedure',
              path: 'procedure/list',
              component: () => import('../components/users/regular/UserProcedure.vue'),
            },
            {
              name: 'userProcedureDetails',
              path: 'procedure/details/:uid',
              component: () => import('../components/users/regular/UserProcedureDetails.vue'),
            },
            {
              name: 'mergeAccounts',
              path: 'mergeAccounts',
              component: () => import('../components/users/MergeAccounts.vue'),
            },
          ],
        },
        {
          name: 'closedcaption',
          path: 'closed-caption',
          component: EmptyParentComponent,
          children: [
            {
              name: 'closedcaption-new',
              path: 'create',
              component: ()=>import('../components/closedcaption/create.vue'),
            },
            {
              name: 'closedcaption-training',
              path: 'training',
              component: () => import('../components/closedcaption/training.vue'),
            },
            {
              name: 'closedcaption-update',
              path: 'update',
              component: ()=>import('../components/closedcaption/table.vue'),
            },
          ],
        },
        {
          name: 'resources',
          path: 'resources',
          component: EmptyParentComponent,
          children: [
            {
              name: 'resources-new',
              path: 'create',
              component: ()=>import('../components/resources/create.vue'),
            },
            {
              name: 'resources-view',
              path: 'view',
              component: () => import('../components/resources/view.vue'),
            },
          ],
        },
        {
          name: 'Quotes',
          path: 'quotes',
          component: EmptyParentComponent,
          children: [
            {
              name: 'quoteTable',
              path: 'list',
              component: () => import('../components/quotes/list.vue'),
            },
            {
              name: 'quoteDetails',
              path: 'details/:id',
              component: () => import('../components/quotes/details.vue'),
            },
          ]
        }, 
        {
          name: 'editor',
          path: 'editor',
          component: EmptyParentComponent,
          children: [
            {
              name: 'globalEnvironment',
              path: 'globalEnvironment',
              component: () => import('../components/editor/globalEnvironment.vue'),
            },
            {
              name: 'translation',
              path: 'translation',
              component: () => import('../components/editor/translation.vue'),
            },
            {
              name: 'exam',
              path: 'exam',
              component: () => import('../components/editor/exam.vue'),
            },
            {
              name: 'courseSelection',
              path: 'course',
              component: () => import('../components/editor/courseSelection.vue'),
            },
            {
              name: 'newCourse',
              path: 'newCourse',
              component: () => import('../components/editor/newCourse.vue'),
            },
            {
              name: 'course',
              path: 'course/:uid',
              component: () => import('../components/editor/course.vue'),
            },
            {
              name: 'model',
              path: 'model',
              component: () => import('../components/editor/model.vue'),
            },
            {
              name: 'email',
              path: 'email',
              component: () => import('../components/editor/email.vue'),
            },
            {
              name: 'editTemplate',
              path: 'email/:id',
              component: ()=>import('../components/editor/editTemplate.vue'),
            },
            {
              name: 'eval',
              path: 'eval/:evalId?',
              component: ()=>import('../components/editor/eval.vue'),
            },
            {
              name: 'evalPreview',
              path: 'eval/preview/:evalId?',
              component: ()=>import('../components/editor/evalPreview.vue'),
            },
            {
              name: 'credentials',
              path: 'credentials',
              component: ()=>import('../components/editor/credentials.vue'),
            },
            {
              name: 'faq',
              path: 'faq',
              component: ()=>import('../components/editor/faq.vue'),
            },
            {
              name: 'greenCards',
              path: 'greenCards',
              component: ()=>import('../components/editor/greenCards.vue'),
            },
          ],
        },
        {
          name: 'evaluation',
          path: 'evaluation',
          component: EmptyParentComponent,
          children: [
            {
              name: 'evaluation-statistics',
              path: 'evaluation-statistics',
              component: ()=>import('../components/evaluations/evaluations-statistics.vue'),
            },
            {
              name: 'evaluation-statistics-unique',
              path: 'evaluation-statistics/:userID',
              component: ()=>import('../components/evaluations/evaluations-statistics.vue'),
            },
            {
              name: 'evaluation-statistics-unique-eval',
              path: 'evaluation-statistics/:userID/:evalID',
              component: ()=>import('../components/evaluations/evaluations-statistics.vue'),
            },
          ],
        },
        {
          name: 'credentials',
          path: 'credentials',
          component: EmptyParentComponent,
          children: [
            {
              name: 'credential-statistics',
              path: 'statistics',
              component: ()=>import('../components/credentials/statistics.vue'),
            },
          ]
        },
        {
          name: 'tickets',
          path: 'tickets',
          component: EmptyParentComponent,
          children: [
            {
              name: 'show-tickets',
              path: 'show-tickets',
              component: ()=>import('../components/tickets/show-tickets.vue'),
            },
            {
              name: 'nihss-tickets',
              path: 'nihss-tickets/:ticket_id?',
              component: ()=>import('../components/tickets/nihss-tickets.vue'),
            },
            {
              name: 'evaluation-tickets',
              path: 'evaluation-tickets/:ticket_id?',
              component: ()=>import('../components/tickets/evaluation-tickets.vue'),
            },
            {
              name: 'custom-replies',
              path: 'custom-replies',
              component: ()=>import('../components/tickets/custom-replies.vue'),
            },
            {
              name: 'custom-categories',
              path: 'custom-categories',
              component: ()=>import('../components/tickets/custom-categories.vue'),
            },
          ],
        },
        {
          name: 'reports',
          path: 'reports',
          component: EmptyParentComponent,
          children: [
            {
              name: 'patient-statistics',
              path: 'patient-statistics',
              component: ()=>import('../components/reports/patient-statistics.vue'),
            },
            {
              name: 'completed-exams',
              path: 'completed-exams',
              component: ()=>import('../components/reports/completed-exams.vue'),
            },
          ],
        },
        {
          name: 'logs',
          path: 'logs',
          component: EmptyParentComponent,
          children: [
            {
              name: 'mailLog',
              path: 'mail',
              component: ()=>import('../components/logs/mail.vue'),
            },
            {
              name: 'historyLog',
              path: 'history',
              component: ()=>import('../components/logs/history.vue'),
            },
            {
              name: 'reportingLog',
              path: 'reporting',
              component: ()=>import('../components/logs/reporting.vue'),
            }
          ],
        },
        {
          name: 'statistics',
          path: 'statistics',
          component: EmptyParentComponent,
          children: [
            {
              name: 'charts',
              path: 'charts',
              component: () => import('../components/statistics/charts/Charts.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Charts',
              },
            },
            {
              name: 'progress-bars',
              path: 'progress-bars',
              component: () => import('../components/statistics/progress-bars/ProgressBars.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Progress-Bars',
              },
            },
          ],
        },
        {
          name: 'forms',
          path: 'forms',
          component: EmptyParentComponent,
          children: [
            {
              name: 'form-elements',
              path: 'form-elements',
              component: () => import('../components/forms/form-elements/FormElements.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/inputs',
              },
            },
            {
              name: 'medium-editor',
              path: 'medium-editor',
              component: () => import('../components/forms/medium-editor/MediumEditor.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Medium-Editor',
              },
            },
          ],
        },
        {
          name: 'tables',
          path: 'tables',
          component: EmptyParentComponent,
          children: [
            {
              name: 'markup',
              path: 'markup',
              component: () => import('../components/markup-tables/MarkupTables.vue'),
              wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables', // TODO Update docs
            },
            {
              name: 'data',
              path: 'data',
              component: () => import('../components/data-tables/DataTables.vue'),
              wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables', // TODO Add docs
            },
            {
              name: 'ServerSideTable',
              path: 'server-side-table',
              component: () => import('../components/data-tables/ServerSide.vue'),
              wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tables', // TODO Add docs
            },
          ],
        },
        {
          name: 'ui',
          path: 'ui',
          component: EmptyParentComponent,
          children: [
            {
              name: 'typography',
              path: 'typography',
              component: () => import('../components/ui/typography/Typography.vue'),
            },
            {
              name: 'buttons',
              path: 'buttons',
              component: () => import('../components/ui/buttons/Buttons'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Buttons',
              },
            },
            {
              name: 'rating',
              path: 'rating',
              component: () => import('../components/ui/rating/Rating'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Rating',
              },
            },
            {
              name: 'color-pickers',
              path: 'color-pickers',
              component: () => import('../components/ui/color-pickers/ColorPickers'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Color-Pickers',
              },
            },
            {
              name: 'timelines',
              path: 'timelines',
              component: () => import('../components/ui/timelines/Timelines'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Timelines',
              },
            },
            {
              name: 'notifications',
              path: 'notifications',
              component: () => import('../components/ui/notifications/Notifications'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Notifications',
              },
            },
            {
              path: 'icons',
              component: () => import('../components/ui/icons/Icons'),
              children: [
                {
                  name: 'icon-sets',
                  path: '', // Default route
                  component: () => import('../components/ui/icons/SetsList'),
                  meta: {
                    wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Icons',
                  },
                },
                {
                  name: 'icon-set',
                  path: ':name',
                  component: () => import('../components/ui/icons/IconSet'),
                  props: true,
                  meta: {
                    wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Icons',
                  },
                },
              ],
            },
            {
              name: 'spinners',
              path: 'spinners',
              component: () => import('../components/ui/spinners/Spinners'),
            },
            {
              name: 'grid',
              path: 'grid',
              component: () => import('../components/ui/grid/Grid'),
            },
            {
              name: 'modals',
              path: 'modals',
              component: () => import('../components/ui/modals/Modals'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Modals',
              },
            },
            {
              name: 'cards',
              path: 'cards',
              component: () => import('../components/ui/cards/Cards'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Cards',
              },
            },
            {
              name: 'file-upload',
              path: 'file-upload',
              component: () => import('../components/ui/file-upload/FileUpload'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/File-Upload',
              },
            },
            {
              name: 'chips',
              path: 'chips',
              component: () => import('../components/ui/chips/Chips'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Chips',
              },
            },
            {
              name: 'tree-view',
              path: 'tree-view',
              component: () => import('../components/ui/tree-view/TreeView'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tree-view',
              },
            },
            {
              name: 'collapses',
              path: 'collapses',
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Collapse',
              },
              component: () => import('../components/ui/collapse/Collapses'),
            },
            {
              name: 'colors',
              path: 'colors',
              component: () => import('../components/ui/colors/Colors'),
            },
            {
              name: 'spacing',
              path: 'spacing',
              component: () => import('../components/ui/spacing/Spacing'),
            },
            {
              name: 'sliders',
              path: 'sliders',
              component: () => import('../components/ui/sliders/Sliders'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Sliders',
              },
            },
            {
              name: 'popovers',
              path: 'popovers',
              component: () => import('../components/ui/popovers/Popovers'),
            },
            {
              name: 'chat',
              path: 'chatPage',
              component: () => import('../components/ui/chat/ChatPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Chat',
              },
            },
            {
              name: 'tabs',
              path: 'tabs',
              component: () => import('../components/ui/tabs/Tabs'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Tabs',
              },
            },
            {
              name: 'lists',
              path: 'lists',
              component: () => import('../components/lists/Lists.vue'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Lists',
              },
            },
          ],
        },
        {
          name: 'maps',
          path: 'maps',
          component: EmptyParentComponent,
          children: [
            {
              name: 'google-maps',
              path: 'google-maps',
              component: () => import('../components/maps/google-maps/GoogleMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
            {
              name: 'yandex-maps',
              path: 'yandex-maps',
              component: () => import('../components/maps/yandex-maps/YandexMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
            {
              name: 'leaflet-maps',
              path: 'leaflet-maps',
              component: () => import('../components/maps/leaflet-maps/LeafletMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
            {
              name: 'bubble-maps',
              path: 'bubble-maps',
              component: () => import('../components/maps/bubble-maps/BubbleMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
            {
              name: 'line-maps',
              path: 'line-maps',
              component: () => import('../components/maps/line-maps/LineMapsPage'),
              meta: {
                wikiLink: 'https://github.com/epicmaxco/vuestic-admin/wiki/Maps',
              },
            },
          ],
        },
        {
          name: 'pages',
          path: 'pages',
          component: EmptyParentComponent,
          children: [
            {
              name: '404-pages',
              path: '404-pages',
              component: () => import('../components/pages/404-pages/404PagesPage'),
              meta: {
                allowGuest: true,
              },
            },
          ],
        },
      ],
    },
  ],
});

// Nav Guard
router.beforeEach(async (to, from, next) => {
  // return
  const user = await firebase.getCurrentUser();
  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.allowGuest)) {
    next();
  } else {
    if (user && user.admin) {
      // Go to login
      next();
    } else {
      let error = 'Access Denied.';
      if (!user) {
        error = '';
      } else if (!user.admin) {
        error = 'User not an admin.';
        //save error in store
        // this.$store.state.authError = error;
      }
      // Proceed to route
      next({
        name: 'login',
        query: {error: error},
      });
    }
  }
});

export default router;
